const Theme: any = {};

Theme.colors = {
  primary: '#760101',
  secondary: '#5cb85c',
  info: '#5bc0de',
  warning: '#f0ad4e',
  danger: '#d9534f',
  inverse: '#000000',
  faded: '#ffffff',
  gray: '#9d9d9d',
};

Theme.typography = {
  sansSerifFontFamily: '"League Spartan", sans-serif',
  fontSize: '1rem',
  fontWeight: '400',
  lineHeight: '1.5',
  color: Theme.colors.faded,
};

export { Theme };

export type ThemeType = typeof Theme;
